import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import Img from "../components/Img";
import MainPost from "../components/MainPost";
import SEO from "../components/Seo";
import Breadcrumb from "../components/Breadcrumb";

function WikiAnalyticsTemplate({ location, data: { mdx: wikiAnalytics } }) {
  if (!wikiAnalytics) {
    return <p>No Post Found? This should be a 404</p>;
  }


  const analyticsWikiPathsByLanguage = {
    en: "/en/analytics-wiki",
    nl: "/nl/analytics-wiki",
    de: "/de/analytics-wiki",
    se: "/se/analys-wiki",
    no: "/no/analyse-wiki",
    da: "/da/analytics-wiki",
    "x-default": "/en/analytics-wiki"
  };

  const breadCrumbLevels = {
    Home: `/${wikiAnalytics.frontmatter.language}`,
    'Analytics Wiki': analyticsWikiPathsByLanguage[wikiAnalytics.frontmatter.language],
    [wikiAnalytics.frontmatter.title]: `/${wikiAnalytics.frontmatter.language}/${wikiAnalytics.frontmatter.slug}`
  };


  // Create alternateLangs array from the hreflang object
  const alternateLangs = Object.entries(wikiAnalytics.frontmatter.hreflang).map(([lang, href]) => ({
    hreflang: lang === 'x_default' ? 'x-default' : lang,
    href: href.startsWith('/') ? href : `/${href}`
  }));

  return (
    <Layout location={location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title={wikiAnalytics.frontmatter.titletag}
          description={wikiAnalytics.frontmatter.description}
          lang={wikiAnalytics.frontmatter.language}
          alternateLangs={alternateLangs}
          image={wikiAnalytics.frontmatter.image?.name}
          canonical={location.pathname}
          datePublished={wikiAnalytics.frontmatter.publishISO}
          dateModified={wikiAnalytics.frontmatter.updateISO}
        />
        <MainPost>
          <Img src={wikiAnalytics.frontmatter.image?.relativePath} alt={wikiAnalytics.frontmatter.title} />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <MDXRenderer
            timeToRead={wikiAnalytics.timeToRead}
            tags={wikiAnalytics.frontmatter.tags && wikiAnalytics.frontmatter.tags.split(", ")}
            date={wikiAnalytics.frontmatter.update}
          >
            {wikiAnalytics.body}
          </MDXRenderer>
        </MainPost>
      </React.Fragment>

    </Layout>
  );
}

export default WikiAnalyticsTemplate;

export const pageQuery = graphql`
  query WikiAnalyticsQuery($slug: String!, $language: String!) {
    site {
      siteMetadata {
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { language: { eq: $language } }) {
      fields {
        relativePath
        relativeHrefLang
      }
      fileAbsolutePath
      excerpt
      body
      timeToRead
      id
      frontmatter {
        title
        answer
        slug
        hreflang {
          ... on HreflangObject {
            x_default
            da
            de
            en
            fi
            fr
            nl
            no
            se
          }
        }
        titletag
        language
        description
        tags
        update(formatString: "DD. MMMM YYYY")
        updateISO: update(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        publish(formatString: "DD. MMMM YYYY")
        publishISO: publish(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        image {
          id
          relativePath
          name
        }
      }
    }
  }
`;
