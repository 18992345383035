import React, { Component } from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import Overlay from "./Overlay";

const StyledMain = styled.main`
  grid-area: content;
  min-height: 80vh;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr minmax(50px, 600px) 3fr;
  grid-template-areas: "left post right";

  .middle {
    grid-area: post;
  }

  .aside-left {
    grid-area: left;
  }

  .aside-right {
    grid-area: right;
  }

  .article p a,
  .article ul > li > a,
  .article ol > li > a {
    text-decoration: underline;
    text-decoration-color: var(--foregrounddark);
    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }
  }

  .article ul,
  .article ol {
    font-family: -apple-system, Arial, 'Helvetica', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul li,
  ol li {
    margin-bottom: 1rem;
  }

  video {
    margin-bottom: 1rem;
    max-width:100%;
    width:100%
  }

  @media all and (max-width: 700px) {
    grid-template-columns: minmax(300px, 700px);
    -ms-grid-columns: minmax(300px, 700px);
    grid-template-areas: "post";

    .middle > * {
      padding: 0rem 1.5rem 1rem 1.5rem;
    }

    .middle div.overlay,
    .middle div.youtube-container,
    .middle div.gatsby-image-wrapper,
    .middle section.skipper-container,
    .middle>a,
    /* main > div > a:nth-child(2), */
    .middle figure.codesnippet,
    .middle figure.imagecontainer,
    .middle video {
      padding: 0rem 0rem;
    }
  }

  @media (max-width: 415px) {
    /* .middle > div {
      padding: 0rem 0.5rem;
    } */

    .middle > div:first-child {
      padding: 0rem 0rem;
    }
    .breadcrumblevel:nth-child(1) {
      display: none;
    }
    .breadcrumblevel:nth-child(2) {
      white-space: nowrap;
    }
  }
`;

function MainPost({ children }) {
  return (
    <StyledMain role="main">
      <aside className="aside-left" />
      <article className="middle article">
        <Overlay />
        {children}
      </article>
      <aside className="aside-right" />
    </StyledMain>
  );
}

export default MainPost;
